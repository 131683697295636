<template>
  <div class="order">
    <card-title title="我的订单" />
    <div class="order-list">
      <template v-if="orderList.length">
        <order-item v-for="item in orderList" :key="item.id" :item="item" />
      </template>
      <no-data v-else />
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle";
import OrderItem from "./OrderItem";
import { userOrder } from "@/request/user";
import NoData from "@/components/NoData";

export default {
  components: {
    CardTitle,
    OrderItem,
    NoData,
  },
  data() {
    return {
      orderList: [],
    };
  },
  created() {
    this.getUserPackage();
  },
  methods: {
    getUserPackage() {
      userOrder().then((res) => {
        this.orderList = res || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order {
  width: 1440px;
  margin: 0 auto;
  border-radius: 0px 0px 10px 10px;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 40px;

  .order-list {
    padding-left: 80px;
  }
}
</style>
