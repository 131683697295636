var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-item"},[_c('p',{staticClass:"title"},[_c('span',[_vm._v("购买 "+_vm._s(_vm.item.name)+" ("+_vm._s(_vm.item.duration)+_vm._s(_vm.item.timeUnit)+")")]),_c('span',[_vm._v("￥"+_vm._s(_vm._f("formatCentToYuan")(_vm.item.realCost))+"("+_vm._s(_vm.paymentType.name)+")")])]),_c('p',{staticClass:"number"},[_c('span',[_vm._v("订单编号："+_vm._s(_vm.item.orderNo))]),_c('span',{class:[
        'status',
        {
          paid: _vm.item.paymentState === _vm.PaymentStatusEnum.paid,
          unpaid: _vm.item.paymentState === _vm.PaymentStatusEnum.toBePaid,
          closed: _vm.item.paymentState === _vm.PaymentStatusEnum.closed,
        },
      ]},[_vm._v(_vm._s(_vm.PaymentStatus[_vm.item.paymentState] ? _vm.PaymentStatus[_vm.item.paymentState].name : ""))])]),_c('p',{staticClass:"buy-time"},[_vm._v(" 订单时间："+_vm._s(_vm._f("formatDate")(_vm.item.createDate,"YYYY-MM-DD HH:mm"))+" ")]),(_vm.item.paymentState === _vm.PaymentStatusEnum.toBePaid)?_c('a-button',{staticClass:"pay-btn",on:{"click":function($event){return _vm.toPay(_vm.item)}}},[_vm._v(" 去支付 ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }