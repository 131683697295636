<template>
  <div class="no-data" v-if="visible">
    <img src="@/assets/images/no-data.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 800);
  },
};
</script>

<style lang="less" scoped>
.no-data {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
  }
}
</style>
