<template>
  <div class="order-item">
    <p class="title">
      <span>购买 {{ item.name }} ({{ item.duration }}{{ item.timeUnit }})</span>
      <span
        >￥{{ item.realCost | formatCentToYuan }}({{ paymentType.name }})</span
      >
    </p>
    <p class="number">
      <span>订单编号：{{ item.orderNo }}</span>
      <span
        :class="[
          'status',
          {
            paid: item.paymentState === PaymentStatusEnum.paid,
            unpaid: item.paymentState === PaymentStatusEnum.toBePaid,
            closed: item.paymentState === PaymentStatusEnum.closed,
          },
        ]"
        >{{
          PaymentStatus[item.paymentState]
            ? PaymentStatus[item.paymentState].name
            : ""
        }}</span
      >
    </p>
    <p class="buy-time">
      订单时间：{{ item.createDate | formatDate("YYYY-MM-DD HH:mm") }}
    </p>
    <a-button
      v-if="item.paymentState === PaymentStatusEnum.toBePaid"
      class="pay-btn"
      @click="toPay(item)"
    >
      去支付
    </a-button>
  </div>
</template>

<script>
import { Button } from "ant-design-vue";
import {
  PaymentStatus,
  PaymentStatusEnum,
  PaymentType,
  PaymentTypeEnum,
} from "@/config/user";
import { orderByAlipay, orderByBank } from "../../../request/package";

export default {
  components: {
    [Button.name]: Button,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      loading: false,
      PaymentStatus,
      PaymentStatusEnum,
      // PaymentType,
    };
  },
  computed: {
    paymentType() {
      const paymentType = PaymentType[this.item.paymentType];
      return paymentType || {};
    },
  },
  methods: {
    toPay(item) {
      const { timeUnit, duration, paymentType, productId } = item;
      const ratio = timeUnit === "月" ? 1 : 12;
      const months = ratio * duration;
      const params = {
        id: productId,
        months,
      };
      console.log(paymentType);
      if (paymentType === PaymentTypeEnum.bank) {
        this.orderByBank(params);
      } else if (paymentType === PaymentTypeEnum.alipay) {
        this.orderByAlipay(params);
      } else {
        console.log("微信支付");
      }
    },
    orderByBank(params) {
      orderByBank(params).then((res = {}) => {
        const { id } = res;
        this.$router.push({
          path: "/pay/bank-transfer",
          query: {
            id,
          },
        });
      });
    },
    orderByAlipay(params) {
      orderByAlipay(params).then((res) => {
        document.write(res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-item {
  padding: 40px 0 29px 0;
  color: #39425c;
  border-bottom: 1px solid #c4d6fc;
  &:last-child {
    border: 0;
  }

  .title,
  .number {
    display: flex;
    align-items: center;
    span {
      &:last-child {
        margin-left: auto;
      }
    }
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 29px;
  }

  .number {
    margin-bottom: 16px;
    span {
      &:first-child {
        font-size: 18px;
      }
      &:last-child {
        font-size: 22px;
      }
      &.status {
        &.paid {
          color: #27bca5;
        }
        &.unpaid {
          color: #fbb020;
        }
        &.closed {
          color: #f67777;
        }
      }
    }
  }

  .buy-time {
    font-size: 18px;
    margin-bottom: 0;
  }

  .pay-btn {
    width: 160px;
    height: 44px;
    border-radius: 22px;
    background-color: #3661eb;
    color: #edfaff;
    font-size: #edfaff;
    outline: none;
    border: 0;
    margin-top: 16px;
  }
}
</style>
